import React from "react";
import { Link } from "gatsby";
import { withContext } from "~context";
import { PageLayout } from "~components/templates/page-layout";
import { Button } from "~components/utils/button";

const NotFoundPage: React.FC = () => {
  return (
    <PageLayout
      paddingPattern="larger-than-header"
      meta={{ title: "ページが見つかりません" }}
    >
      <h1 style={{ fontSize: "2rem", marginBottom: "48px" }}>
        ページが見つかりませんでした。
      </h1>
      <Link to="/">
        <Button>トップへ戻る</Button>
      </Link>
    </PageLayout>
  );
};

export default withContext(NotFoundPage);
